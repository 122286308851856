// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import moment from 'moment';
import { TIMELINE_GROUPBY_KEYS, TIMELINE_DISPLAY_STRINGS } from './constants';
import { GranularityType } from '@happenings/components/profile';
import { Post } from '@happenings/components/post';

// the ISO 8601 supported string format we use.
export const DATE_FORMAT_STRING = 'YYYY-MM-DD HH:mm';

export const dateFromTimestamp = (timestamp: string) => {
  return moment(timestamp).toDate();
};

export const timestampFromDate = (date: Date) => {
  return moment(date).format(DATE_FORMAT_STRING);
};

const padZero = (baseInt: number) => {
  return baseInt < 10 ? `0${baseInt}` : `${baseInt}`;
};

export const isInThePast = (timestamp: string): boolean => {
  return moment(timestamp).isBefore(moment());
}

/**
 * formDataToDate reconciles all the temporal form info into a single Date object.
 * We store 'hour', 'minute', and Am/Pm info separately from the calendar Date
 * in the store to avoid excessive Date manipulation during state updates.
 */
export const formDataToDate = (
  date: Date,
  hourStr: string,
  minuteStr: string,
  amOrPm: string
) => {
  const dateCopy = new Date(date.getTime());
  const minutes = parseInt(minuteStr); // '15' -> 15
  const hour = parseInt(hourStr); // '02' -> 2
  const hour24 = amOrPm === 'PM' ? hour + 12 : hour;
  dateCopy.setHours(hour24, minutes, 0, 0);
  return dateCopy;
};

export const formFieldsFromDate = (date: Date) => {
  const hour = date.getHours();
  const hour12 = hour > 12 ? hour % 12 : hour;
  const hour12Str = padZero(hour12);
  const minuteStr = date.getMinutes().toString();
  const amOrPm = hour >= 12 ? 'PM' : 'AM';
  return { hour: hour12Str, minute: minuteStr, amOrPm };
};

/**
 * Group a collection of posts by a calendar granularity
 */
export const groupByGranularity = (
  posts: Object[],
  granularity: GranularityType
): Record<string, Post[]> => {
  const grouped = {};
  const groupByFormat = TIMELINE_GROUPBY_KEYS[granularity];

  Object.keys(posts).forEach((postId) => {
    const postData = posts[postId];
    const m = moment(postData.eventTimestamp);
    const groupBy = m.format(groupByFormat);

    if (Object.keys(grouped).includes(groupBy)) {
      grouped[groupBy].push(postData);
    } else {
      grouped[groupBy] = [postData];
    }
  });
  return grouped;
};

export const formatSection = (groupKey, granularity) => {
  // 'YYYY-MM-DD', 'YYYY-MM', etc
  const formatKey = TIMELINE_DISPLAY_STRINGS[granularity];

  // moment cant parse a lone 'YYYY' so just pad it with MM-DD
  let dateStr = groupKey;
  if (formatKey === TIMELINE_DISPLAY_STRINGS.YEAR) {
    dateStr = `${dateStr}-01-01`;
  }
  return moment(dateStr).format(formatKey);
};

// Helper function to chunk array into arrays of three items.
export const groupInThree = (arr: any[]) => {
  if (arr.length === 0) return [];

  const { ungrouped, groups } = arr.slice(3).reduce(
    (accumulator, item) => {
      const { ungrouped, groups } = accumulator;
      const withItem = [...ungrouped, item];
      if (withItem.length === 3)
        return { ungrouped: [], groups: [...groups, withItem] };
      return { ungrouped: withItem, groups };
    },
    // initial accumulator begins with the first three posts
    { ungrouped: [], groups: [[...arr.slice(0, 3)]] }
  );
  // If the array's length is not divisble by three, the last chunk
  // will have less than three items
  return ungrouped.length > 0 ? [...groups, ungrouped] : groups;
};

export const conciseTimeAgo = (isoTimestamp: string) => {
  const timestamp = new Date(isoTimestamp).getTime();
  const now = new Date().getTime();
  const deltaSeconds = Math.floor((now - timestamp) / 1000);

  const SECONDS_IN_A_YEAR = 365 * 86400;
  const SECONDS_IN_A_MONTH = 30 * 86400;
  const SECONDS_IN_A_WEEK = 7 * 86400;
  const SECONDS_IN_A_DAY = 86400;
  const SECONDS_IN_AN_HOUR = 3600;
  const SECONDS_IN_A_MINUTE = 60;

  if (deltaSeconds >= SECONDS_IN_A_YEAR) {
    const years = Math.floor(deltaSeconds / SECONDS_IN_A_YEAR);
    return `${years}y`;
  } else if (deltaSeconds >= SECONDS_IN_A_MONTH) {
    const months = Math.floor(deltaSeconds / SECONDS_IN_A_MONTH);
    return `${months}mo`;
  } else if (deltaSeconds >= SECONDS_IN_A_WEEK) {
    const weeks = Math.floor(deltaSeconds / SECONDS_IN_A_WEEK);
    return `${weeks}w`;
  } else if (deltaSeconds >= SECONDS_IN_A_DAY) {
    const days = Math.floor(deltaSeconds / SECONDS_IN_A_DAY);
    return `${days}d`;
  } else if (deltaSeconds >= SECONDS_IN_AN_HOUR) {
    const hours = Math.floor(deltaSeconds / SECONDS_IN_AN_HOUR);
    return `${hours}h`;
  } else if (deltaSeconds >= SECONDS_IN_A_MINUTE) {
    const minutes = Math.floor(deltaSeconds / SECONDS_IN_A_MINUTE);
    return `${minutes}m`;
  } else {
    return `${deltaSeconds}s`;
  }
}

// human readable from ISO timestamp
export const humanReadableTime = (isoTimestamp: string) => {
  const date = moment(isoTimestamp);
  const currentYear = moment().year();
  const formatString = date.year() === currentYear ? 'MMMM Do' : 'MMMM Do, YYYY';
  return date.format(formatString);
};